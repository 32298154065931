import React, { FC } from 'react';
import cx from 'classnames';

import useGatsbyRefresh from './useGatsbyRefresh';

import { PreviewWidgetProps } from './models';

import './PreviewWidget.scss';

const PreviewWidget: FC<PreviewWidgetProps> = ({ cmsUrl }) => {
  const { startRefresh, requestState, message } = useGatsbyRefresh();

  const refreshButtonLabels = {
    default: 'Refresh content',
    success: 'Refreshing in progress...',
    error: 'Refresh failed',
  };

  const onRefreshClick = async () => {
    await startRefresh();
  };

  return (
    <div className="phx-preview-widget" data-testid="phx-preview-widget">
      <button type="button" className="phx-preview-widget__trigger">
        ✏️
      </button>
      <div className="phx-preview-widget__content">
        {message ? (
          <div
            className={cx('phx-preview-widget__status', {
              [`phx-preview-widget__status--${requestState}`]: requestState,
            })}
          >
            {message}
          </div>
        ) : null}
        <button
          type="button"
          className="phx-preview-widget__button phx-preview-widget__button--refresh"
          onClick={onRefreshClick}
          disabled={!!requestState}
        >
          {refreshButtonLabels[requestState || 'default']}
          {!requestState ? (
            <div className="phx-preview-widget__hint">
              <span>🤔</span>
              <div className="phx-preview-widget__hint-content">
                Unfortunately it&apos;s not possible to determine when the content refresh process
                is finished. You need to click the button and be patient 😎 The page will be
                reloaded automatically when new content is available.
              </div>
            </div>
          ) : null}
        </button>
        <a
          href={cmsUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="phx-preview-widget__button phx-preview-widget__button--refresh"
        >
          Go to CMS
        </a>
      </div>
    </div>
  );
};

export default PreviewWidget;
